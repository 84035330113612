<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper" @click="close">
                <div class="modal-container">
                    <slot />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "Modal",

    mounted() {
        document.querySelector('body').style.overflowY = 'hidden';
    },

    destroyed() {
        document.querySelector('body').style.overflowY = 'auto';
    },

    methods: {
        close(e) {
            (e.currentTarget === e.target) && this.$emit('close');
        }
    }
};
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: fit-content;
    margin: auto;
}

.modal-default-button {
    float: right;
    padding: 5px 20px;
    position: relative;
    top: -20px;
}

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>
