<template>
  <div class="bio-card-modal">
    <article class="card narrow">
      <div class="card-inner">
        <img
          :src="`https://s3.amazonaws.com/s3.pilates.com/scholarship/${bio.image}@1x.jpg`"
          :srcset="`
            https://s3.amazonaws.com/s3.pilates.com/scholarship/${bio.image}@1x.jpg 1x,
            https://s3.amazonaws.com/s3.pilates.com/scholarship/${bio.image}@2x.jpg 2x,
            https://s3.amazonaws.com/s3.pilates.com/scholarship/${bio.image}@2x.jpg 3x
          `"
          :alt="`${bio.name} headshot`"
        />
        <div class="flex">
          <p class="title">{{bio.name}}</p>
          <p v-html="bio.description"></p>
          <div class="links">
            <a :href="`https://www.instagram.com/${bio.ig}/`" class="social ig" target="_blank"><span class="screenreader">Visit Instagram</span></a>
            <a :href="`https://www.facebook.com/${bio.fb}`" class="social fb" target="_blank"><span class="screenreader">Visit Facebook</span></a>
            <a :href="`${bio.url}`" class="social web" target="_blank"><span class="screenreader">Visit Website</span></a>
          </div>
        </div>
      </div>
      <p class="small" v-html="bio.courses"></p>
      <hr>
      <div v-html="bio.content" class="p1" />
    </article>

    <article class="card wide">
      <div class="flex-container">
        <div class="card-inner flex">
          <img
            :src="`https://s3.amazonaws.com/s3.pilates.com/scholarship/${bio.image}@1x.jpg`"
            :srcset="`
              https://s3.amazonaws.com/s3.pilates.com/scholarship/${bio.image}@1x.jpg 1x,
              https://s3.amazonaws.com/s3.pilates.com/scholarship/${bio.image}@2x.jpg 2x,
              https://s3.amazonaws.com/s3.pilates.com/scholarship/${bio.image}@2x.jpg 3x
            `"
            :alt="`${bio.name} headshot`"
          />
          <p class="title">{{bio.name}}</p>
          <p v-html="bio.description"></p>
          <div class="flex-links">
            <a :href="`https://www.instagram.com/${bio.ig}/`" class="ig" target="_blank"><span class="screenreader">Visit Instagram</span></a>
            <a :href="`https://www.facebook.com/${bio.fb}`" class="fb" target="_blank"><span class="screenreader">Visit Facebook</span></a>
            <a :href="`${bio.url}`" class="web" target="_blank"><span class="screenreader">Visit Website</span></a>
          </div>
          <hr>
          <p class="small" v-html="bio.courses"></p>
        </div>
        <div v-html="bio.content" class="p1" />
      </div>
    </article>
  </div>
</template>

<script>
  export default {
    name: "BioCardModal",

    props: {
      bio: Object
    }
  }
</script>

<style scoped>
.narrow {
  display: block;
}

.wide {
  display: none;
}

hr {
  border: none;
  border-top: 4px dotted #abab78;
  margin: 20px 0;
  width: 112px;
}

.bio-card-modal {
  height: 75vh;
}
.card-inner img {
  border: 1px solid #919191;
  margin-right: 12px;
  height: 154px;
  object-fit: cover;
}

.card-inner {
  display: flex;
  padding: 20px 0;
  position: relative;
  z-index: 10;
}

.card-inner p.title {
  font-weight: 500;
  font-size: 20rem;
  line-height: 1.41;
  margin-bottom: 10px;
}

.card-inner p {
  font-weight: 400;
  font-size: 16rem;
  line-height: 1.41;
}

p.small {
  font-weight: 400;
  font-size: 12rem;
  line-height: 1.41;
}

.links {
  display: flex;
  flex-flow: row nowrap;
}

.flex {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
}

.ig,
.fb,
.web {
  background-color: #fff;
  height: 46px;
  width: 46px;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s;
}

.ig {
  background-image: url('~@/assets/images/ig.svg');
  background-size: 35px;
}

.fb {
  background-image: url('~@/assets/images/fb.svg');
  background-size: 16px;
}

.web {
  background-image: url('~@/assets/images/web.svg');
  background-size: 37px;
}

a:hover.ig {
  background-image: url('~@/assets/images/ig-clr.svg');
}

a:hover.fb {
  background-image: url('~@/assets/images/fb-clr.svg');
}

a:hover.web {
  background-image: url('~@/assets/images/web-clr.svg');
}

.screenreader {
  position: absolute !important; /* Outside the DOM flow */
  height: 1px; width: 1px; /* Nearly collapsed */
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
  clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
}

@media only screen and (min-width: 768px) {
  .narrow {
    display: none;
  }

  .wide {
    display: block;
  }

  .card {
    width: 100%;
    height: 278px;
    padding: 25px 15px 25px 23px;
    position: relative;
    margin: 8px;
  }

  .card-inner {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 22px;
  }

  .card-inner p.title {
    font-size: 17rem;
    margin-top: 15px;
  }

  .card-inner p {
    font-size: 14rem;
  }

  .card-inner p.small {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12rem;
  }

  .card-inner img {
    height: 228px;
    object-fit: cover;
  }

  hr {
    margin: 20px 0 5px;
  }

  .ig {
    background-size: auto;
  }

  .fb {
    background-size: auto;
  }

  .web {
    background-size: auto;
  }

  .flex {
    align-items: flex-start;
  }

  .flex-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
  }

  .flex-links {
    display: flex;
    justify-content: flex-start;
  }

  .flex-links a {
    width: 35px;
  }

  .flex-links a:first-of-type {
    margin-left: -5px;
  }
}
</style>
