var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bio-card-modal"},[_c('article',{staticClass:"card narrow"},[_c('div',{staticClass:"card-inner"},[_c('img',{attrs:{"src":`https://s3.amazonaws.com/s3.pilates.com/scholarship/${_vm.bio.image}@1x.jpg`,"srcset":`
          https://s3.amazonaws.com/s3.pilates.com/scholarship/${_vm.bio.image}@1x.jpg 1x,
          https://s3.amazonaws.com/s3.pilates.com/scholarship/${_vm.bio.image}@2x.jpg 2x,
          https://s3.amazonaws.com/s3.pilates.com/scholarship/${_vm.bio.image}@2x.jpg 3x
        `,"alt":`${_vm.bio.name} headshot`}}),_c('div',{staticClass:"flex"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.bio.name))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.bio.description)}}),_c('div',{staticClass:"links"},[_c('a',{staticClass:"social ig",attrs:{"href":`https://www.instagram.com/${_vm.bio.ig}/`,"target":"_blank"}},[_c('span',{staticClass:"screenreader"},[_vm._v("Visit Instagram")])]),_c('a',{staticClass:"social fb",attrs:{"href":`https://www.facebook.com/${_vm.bio.fb}`,"target":"_blank"}},[_c('span',{staticClass:"screenreader"},[_vm._v("Visit Facebook")])]),_c('a',{staticClass:"social web",attrs:{"href":`${_vm.bio.url}`,"target":"_blank"}},[_c('span',{staticClass:"screenreader"},[_vm._v("Visit Website")])])])])]),_c('p',{staticClass:"small",domProps:{"innerHTML":_vm._s(_vm.bio.courses)}}),_c('hr'),_c('div',{staticClass:"p1",domProps:{"innerHTML":_vm._s(_vm.bio.content)}})]),_c('article',{staticClass:"card wide"},[_c('div',{staticClass:"flex-container"},[_c('div',{staticClass:"card-inner flex"},[_c('img',{attrs:{"src":`https://s3.amazonaws.com/s3.pilates.com/scholarship/${_vm.bio.image}@1x.jpg`,"srcset":`
            https://s3.amazonaws.com/s3.pilates.com/scholarship/${_vm.bio.image}@1x.jpg 1x,
            https://s3.amazonaws.com/s3.pilates.com/scholarship/${_vm.bio.image}@2x.jpg 2x,
            https://s3.amazonaws.com/s3.pilates.com/scholarship/${_vm.bio.image}@2x.jpg 3x
          `,"alt":`${_vm.bio.name} headshot`}}),_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.bio.name))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.bio.description)}}),_c('div',{staticClass:"flex-links"},[_c('a',{staticClass:"ig",attrs:{"href":`https://www.instagram.com/${_vm.bio.ig}/`,"target":"_blank"}},[_c('span',{staticClass:"screenreader"},[_vm._v("Visit Instagram")])]),_c('a',{staticClass:"fb",attrs:{"href":`https://www.facebook.com/${_vm.bio.fb}`,"target":"_blank"}},[_c('span',{staticClass:"screenreader"},[_vm._v("Visit Facebook")])]),_c('a',{staticClass:"web",attrs:{"href":`${_vm.bio.url}`,"target":"_blank"}},[_c('span',{staticClass:"screenreader"},[_vm._v("Visit Website")])])]),_c('hr'),_c('p',{staticClass:"small",domProps:{"innerHTML":_vm._s(_vm.bio.courses)}})]),_c('div',{staticClass:"p1",domProps:{"innerHTML":_vm._s(_vm.bio.content)}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }