<template>
  <div class="scholarship">
    <section class="intro">
      <img
        src="@/assets/images/banner-scholarship@1x.jpg"
        srcset="
          @/assets/images/banner-scholarship@1x.jpg 1x,
          @/assets/images/banner-scholarship@2x.jpg 2x,
          @/assets/images/banner-scholarship@3x.jpg 3x
        "
        alt="EXO Chair workout photo"
        class="banner"
      />
      <div class="intro-content">
        <h1 class="h1 narrow">Balanced Body Scholarship Programs</h1>
        <h1 class="h2 wide">Balanced Body Scholarship Programs</h1>
        <hr>
        <p class="p2">Pilates teachers are in demand all over the world. As a career it is portable, flexible and pays well. To expand education and career opportunities for Black Pilates Instructors, we’re supporting two distinct scholarship programs.</p>
      </div>
    </section>

    <section class="primary">
      <div class="primary-inner">
        <div class="flex">
          <h3 class="h3">Why is Scholarship Important?</h3>
          <p class="p3">Our scholarship programs help to remove financial barriers and provide support to current and aspiring Black Pilates instructors. The intention of both programs is to provide a safe, welcoming learning environment where students can explore the life-changing benefits of Pilates. Ultimately, we hope our Black Pilates Instructors bring the methodology back into their communities, thereby helping to drive change in the Pilates community at the grass-roots level. As diversity makes all things stronger, the entire Pilates industry will be elevated as instructors with new perspectives and cultural experiences enter the field.</p>
        </div>
        <img
          src="@/assets/images/img-scholarship@1x.jpg"
          srcset="
            @/assets/images/img-scholarship@1x.jpg 1x,
            @/assets/images/img-scholarship@2x.jpg 2x,
            @/assets/images/img-scholarship@3x.jpg 3x,
          "
          alt="EXO Chair workout with instructor" />
      </div>
      <span class="triangle" />
    </section>

    <section>
      <div class="video">
        <img
          class="video-banner"
          src="@/assets/images/video-scholarship@1x.jpg"
          srcset="
            @/assets/images/video-scholarship@1x.jpg 1x,
            @/assets/images/video-scholarship@2x.jpg 2x,
          "
          alt="Photo of video"
        />
        <img 
            src="@/assets/images/video-play-icon.svg"
            alt="Play icon"
            class="video-play-icon"
            @click="showModal=true"
            tabindex="0"
            role="button"
        />
      </div>
      <modal v-if="showModal" @close="showModal=false">
        <div style="
          position: relative; 
          width: 100vw;
          height: calc((810/1440) * 100vw);
          max-height: min(650px, 90vh);
          max-width: calc((1440/810) * min(650px, 90vh));
        ">
          <iframe 
            src="https://player.vimeo.com/video/752279092?h=a49e9e8f61&autoplay=0&loop=1&title=0&byline=0&portrait=0&controls=1&muted=0" 
            style="position:absolute;top:0;left:0;width:100%;height:100%;" 
            frameborder="0" 
            allow="autoplay; fullscreen; picture-in-picture" 
            allowfullscreen 
            title="Diversity in Pilates video"
          />
        </div>
      </modal>
    </section>

    <section class="card-wrapper">
      <div class="card">
        <header class="orange narrow">
          <h2 class="title">Pilates Joyful Movement Scholarship Program</h2>
          <span class="notch orange"></span>
        </header>
        <img
          src="@/assets/images/img-scholarshipprogram1@1x.jpg"
          srcset="
            @/assets/images/img-scholarshipprogram1@1x.jpg 1x,
            @/assets/images/img-scholarshipprogram1@2x.jpg 2x,
            @/assets/images/img-scholarshipprogram1@3x.jpg 3x
          "
          alt="Photo of mat workout with instructor and student"
          class="feature"
        />
        <div class="set-basis">
          <header class="orange wide">
            <h2 class="title">Pilates Joyful Movement Scholarship Program</h2>
            <span class="notch orange"></span>
          </header>
          <div class="content">
            <p class="p4">Balanced Body is partnering with <a href="" @click.prevent="$refs.modal1.openModal()" class="link">Tonya Amos</a> on a pilot program in the San Francisco Bay Area to provide Pilates and life skills instruction to aspiring teachers. The goal of this program is to help impact resilience, resistance, self-empowerment, and joy in Black communities. Through arts and Pilates training, nutrition, counseling, financial literacy, and job placement, Pilates teachers will become audacious wellness leaders in their communities. Well paid careers will be created and health disparities in under resourced communities will be impacted. This program will begin in September, 2021. Once the program is proven through the Pilot program, our intention is to roll it out to other communities.</p>
            <hr>
            <p class="special"><a href="https://www.grownwomendance.org/training-and-certification">Read more and connect with Tonya</a></p>
            <router-link to="joyful-movement" class="atc-cta" style="font-weight: 700;">Join Us in Fundraising for Joyful Movement Whole Life Scholarship</router-link>
          </div>
        </div>
      </div>

      <div class="card">
        <header class="blue narrow">
          <h2 class="title">Community Expansion Scholarship Program</h2>
          <span class="notch blue"></span>
        </header>
        <img
          src="@/assets/images/img-scholarshipprogram2@1x.jpg"
          srcset="
            @/assets/images/img-scholarshipprogram2@1x.jpg 1x,
            @/assets/images/img-scholarshipprogram2@2x.jpg 2x,
            @/assets/images/img-scholarshipprogram2@3x.jpg 3x
          "
          alt="Photo of woman working out on Reformer with Sitting Box"
          class="feature"
        />
        <div class="set-basis">
          <header class="blue wide">
            <h2 class="title">Community Expansion Scholarship Program</h2>
            <span class="notch blue"></span>
          </header>
          <div class="content">
            <p class="p4">The goal of the Balanced Body Community Expansion Scholarship Program is to increase the diversity of the Pilates profession by training teachers who come from Black and other underrepresented communities. Balanced Body Authorized Training Centers around the country and around the world are participating in this program by providing full scholarships to qualified students.</p>
            <h5 class="h5">Program Goals:</h5>
            <ul class="p4">
              <li>To bring more Black instructors into the Pilates community, increasing the rich tapestry of experiences and perspectives in the industry</li>
              <li>To support Black instructors as they develop their teaching practice</li>
              <li>To bring the benefits of Pilates to the Black community by supporting teachers from the community</li>
            </ul>
            <hr>
            <p class="p4">Thank you for your interest in the Balanced Body Community Expansion Scholarship Program.<br><br>Please read the Community Expansion Scholarship program's <a href="https://s3.amazonaws.com/s3.pilates.com/resources/6-26-23_scholarship_application_guidelines_ info_letterV7.pdf" target="_blank">informational letter</a> <b style="font-weight: bold;">before applying</b>. Then proceed to filling out the <a href="https://docs.google.com/forms/d/e/1FAIpQLSfpIfLKTuTAkdCY5_FejYQ2c8XctpZrmVi4WVoSjfQl9XHFtg/viewform?vc=0&c=0&w=1&flr=0" target="_blank">application</a>.<br><br>Please contact us at <a href="mailto:education@pilates.com">education@pilates.com</a> with any questions.</p>

            <!--<router-link to="authorized-training-centers" class="atc-cta">View Participating Authorized Training Centers</router-link>-->
          </div>
        </div>
      </div>
    </section>

    <section class="quote">
      <div class="quote-inner">
        <img src="@/assets/images/quotes.svg" alt="Quotation marks" />
        <div class="flex">
          <p class="callout">Give me a place to stand and I shall move the earth.</p>
          <hr>
          <h5 class="h5">Chinua Achebe</h5>
        </div>
      </div>
      <span class="triangle2" />
    </section>

    <app-modal ref="modal1">
      <template v-slot:header />
      <template v-slot:body>
        <bio-card-modal
          :bio=bios[0]
        />
      </template>
      <template v-slot:footer />
    </app-modal>

  </div>
</template>

<script>
import AppModal from "@/components/AppModal.vue";
import BioCardModal from "@/components/BioCardModal.vue";
import Modal from "@/components/Modal.vue";

  export default {
    name: "Scholarship",

    metaInfo: {
      title: "Balanced Body Scholarship Programs",
      titleTemplate: "%s | Diversity in Pilates",
      meta: [
        {
          name: "description",
          content: "Pilates teachers are in demand all over the world. As a career it is portable, flexible and pays well. To expand education and career opportunities for Black Pilates Instructors, we’re supporting two distinct scholarship programs."
        }
      ]
    },

    components: {
      AppModal,
      BioCardModal,
      Modal,
    },

    data() {
      return {
        showModal: false,
        bios: [
          {
            image: "img-tonya-amos",
            name: "Tonya Amos",
            description: "Owner of Aspire Pilates<br>Dancer &amp; Activist<br>Concord, California",
            ig: "GrownWomenDanceCollective",
            fb: "GrownWomenDanceCollective",
            url: "https://grownwomendance.org/",
            courses: "Pilates, AI3D",
            modal: "modal5",
            title: "Elder Power: Keeping our Seniors Strong",
            date: "Thursday, Mar 4, 2021<br/>Thursday, Mar 18, 2021",
            banner: "Mar 2021",
            zoom: "https://zoom.us/meeting/register/tJMud-yrrDwpG9djGPGEh8hmlQLTfOZfLcIF",
            color: "event5",
            content: "<p class='p1'>Tonya Marie Amos received her B.A. in Anthropology from U.C. Berkeley, and danced 4 years on scholarship at Alvin Ailey American Dance Center, followed by 15 years professionally in New York. Introduced to Pilates over 40 years ago as a young, injured dancer, Tonya received her comprehensive Pilates training in 2004 with Nora St. John and Naomi Leiserson, and since furthered her skills studying with 30+ Master Teachers.</p><p class='p1'>Owner of award-winning Aspire Pilates Center and Artistic Director of Grown Women Dance Collective, Tonya uniquely combines arts and wellness for social justice. Her stunning annual Juneteenth dance concert teaches and celebrates Black history. She is developing the Joyful Movement Pilates Life Skills Teacher Training program, which will help increase resilience, resistance, self-empowerment and joy in Black communities. She brings inspiring, healing experiences to communities with traditionally no access and is proud to foster cross-cultural and intergenerational bridges in her work.</p>"
          }
        ]
      }
    }
  }
</script>

<style scoped>
.narrow {
  display: block;
}

.wide {
  display: none;
}

.intro {
  margin-bottom: 50px;
}

.banner {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.intro-content {
  padding: 35px 34px 35px 32px;
}

.intro hr {
  border: none;
  border-top: 4px dotted #9EB675;
  margin: 40px 0 20px;
  width: 158px;
}

.primary {
  padding: 65px 32px;
  position: relative;
  background-color: var(--warm1);
}

.primary .h3 {
  color: #3e3c53;
}

.primary .p3 {
  color: #3e3c53;
  margin: 15px 0 30px;
}

.primary img {
  width: 100%;
  border-radius: 8px;
}

.primary-inner {
  position: relative;
  z-index: 10;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: max(268px, 35vw) 0 0 max(324px, 45vw);
  border-color: transparent transparent transparent var(--warm2);
  position: absolute;
  bottom: 0;
  left: 0;
}

.card-wrapper {
  padding: 50px 24px 0;
  background-image: url("https://s3.amazonaws.com/s3.pilates.com/scholarship/pattern-triangles-gray.svg");
  background-position: left center;
  background-repeat: no-repeat;
}

.card {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);
  margin-bottom: 40px;
}

.orange {
  background-color: var(--event1);
}

.blue {
  background-color: var(--event6);
}

.card header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
}

.notch {
  display: block;
  width: 28px;
  height: 28px;
  transform: rotate(45deg);
  position: absolute;
  bottom: -14px;
  left: 45%;
}

h2.title {
  font-style: normal;
  font-weight: 500;
  font-size: 18rem;
  line-height: 1.167;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fff;
  padding: 22px 32px;
}

.feature {
  height: 202px;
  width: 100%;
  object-fit: cover;
  object-position: center 35%;
}

.content {
  padding: 30px 30px 60px;
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.content h5 {
  color: #919191;
  margin: 20px 0 10px;
}

.content hr {
  border: none;
  border-top: 4px dotted #abab78;
  margin: 25px 0;
  width: 112px;
}

.special {
  font-style: normal;
  font-weight: 500;
  font-size: 14rem;
  line-height: 1.429;
  color: #555658;
  margin-bottom: 10px;
}

.special a,
.p5 a {
  text-decoration: none;
  color: var(--link);
}

.special a:hover {
  text-decoration: underline;
}

.link {
  text-decoration: underline;
  color: var(--link);
}

ul {
  margin-bottom: 20px;
}

li:before {
  content: "‣";
  padding-right: 10px;
}

li {
  padding-left: 16px;
  text-indent: -16px;
  padding-bottom: 10px;
}

.quote {
  background-color: var(--warm1);
  padding: 125px 32px 65px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  margin-top: -100px;
  z-index: -100;
}

.quote-inner {
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 10;
}

.quote .flex {
  max-width: 700px;
}

.quote img {
  margin: 6px 10px 0 0;
}

.quote hr {
  border: none;
  border-top: 4px dotted #9eb675;
  margin: 25px 0 16px;
  width: 70px;
}

.quote .h5 {
  color: #919191;
}

.triangle2 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: max(208px, 25vw) 0 0 max(264px, 35vw);
  border-color: transparent transparent transparent var(--warm2);
  position: absolute;
  bottom: 0;
  left: 0;
}

.atc-cta {
  display: block;
  border: 2px solid #16A87C;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14rem;
  line-height: 1.43;
  text-align: center;
  color: #5E5F61;
  text-decoration: none;
  margin-top: 25px;
  padding: 5px 0;
}

.video {
  display: grid;
  place-items: center;
  background: #958B85;
}
.video .video-banner {
  grid-column: 1;
  grid-row: 1;
  max-width: 100%;
  min-height: 305px;
  object-fit: cover;
  object-position: center;
}
.video .video-play-icon {
  grid-column: 1;
  grid-row: 1;
  width: 100px;
  height: 100px;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .narrow {
    display: none;
  }

  .wide {
    display: block;
  }

  .intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1052px;
    margin: 0 auto 50px;
    padding: 0 min(calc(1084px - 100vw), 32px);
  }

  .intro hr {
    margin: 50px 0 20px;
  }

  .intro-content {
    order: 1;
    width: 41%;
    padding: 0;
  }

  .banner {
    order: 2;
    width: 57%;
    max-width: 607px;
    height: 510px;
  }

  .primary {
    overflow: hidden;
    padding: 96px min(calc(1084px - 100vw), 32px);
  }

  .primary-inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .primary-inner img {
    order: 1;
    max-width: 277px;
    margin-right: 30px;
  }

  .primary-inner .flex {
    order: 2;
    max-width: 500px;
  }

  .primary .p3 {
    margin-bottom: 0;
  }

  .card-wrapper {
    padding: 80px 24px 0;
  }

  .card {
    display: flex;
    background-color: #fff;
    max-width: 920px;
    margin: 0 auto 40px;
  }

  .card header {
    border-top-left-radius: 0;
  }

  h2.title {
    padding: 32px;
    display: block;
  }

  .feature {
    width: 327px;
    height: auto;
    border-top-left-radius: 8px;
    flex-basis: 327px;
    object-position: 75%;
  }

  .content {
    padding: 30px;
  }

  .set-basis {
    flex-basis: 593px;
  }
}
</style>
